// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$primary: #117cc2;
$secondary: #0f4f7b;
$third: #01bbf6;
$inactive: #8c8c8c;
$selected: #5e5e5e;

$border: rgba(0, 0, 0, 0.2);

$notfilled: #e9ecef;

$palette0: #ccc;
$palette1: #01bbf6;
$palette2: #05A0D7;
$palette3: #0885B9;
$palette4: #0C6A9A;
$palette5: #0f4f7b;

$tcs: #00acf2;