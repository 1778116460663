.calendar-card .card-body { white-space: nowrap; overflow-x: auto; }

.calendar.week { display: grid; grid-template-columns: max-content auto; }
.calendar.week .cell { position: relative; }

.calendar.week .dates .days { display: grid; grid-template-columns: repeat(7, minmax(80px, 1fr)); }
.calendar.week:not(.shifts) .dates .days { display: grid; grid-template-columns: repeat(7, minmax(50px, 1fr)); }

.calendar.week .day { text-align: center; text-transform: uppercase; color: $primary; border-right: 1px solid $border; }
.calendar.week .day .number { font-weight: bold; font-size: 1.3em; }
.calendar.week .day .number span.today { color: #fff; background-color: $primary; border-radius: 50%; }

.calendar.week .hour { text-align: right; padding-right: .5em; border-right: 1px solid $border;  }
.calendar.week .hour:not(:first-child)::before { content: ''; height: 0.5px; background: $border; display: block; position: relative; left: 2.8em; width: 0.85em; top: 0.8em; }
.calendar.week .cell.hr { border-right: 1px solid $border; }
.calendar.week .cell.hr::before { content: ''; height: 0.5px; background: $border; display: block; position: relative; top: 0.8em; }
.calendar.week:not(.shifts) .cell { cursor: pointer; }
.calendar.week .hour:not(.day), .calendar.week .cell { min-height: 2.4em; }

.calendar.week .event { position: absolute; }

.calendar.month .dates, .calendar.month .days { display: grid; grid-template-columns: repeat(7, minmax(90px, 1fr)); }
.calendar.month .days .day { text-align: center; text-transform: uppercase; color: $primary; border-left: 1px solid $border; font-weight: bold; font-size: 1.3em; }
.calendar.month .days .day:last-child { border-right: 1px solid $border; }

.calendar.month .dates span.number { color: $primary; font-weight: bold; }
.calendar.month .dates span.today { color: #fff; background-color: $primary; border-radius: 50%; }
.calendar.month .dates .day { border-top: 1px solid $border; border-left: 1px solid $border; }
.calendar.month .dates .day:nth-child(7n) { border-right: 1px solid $border; }

.calendar.month .dates .day { min-height: 3.8em; }
.calendar.month .dates .day .events { position: relative; }
.calendar.month .dates .day .events .event { position: absolute; }

.calendar.month:not(.shifts) .dates .day { cursor: pointer; }

.calendar .event { font-size: 0.85em; cursor: pointer; margin: 3px; z-index: 1; border-radius: 0.25rem; border: 0.5px solid $border; background-color: #fff; border-top: 3px solid #dee2e6; }

.calendar .event.enrolled { border-color: $success; background-color: $success; color: #fff; }
.calendar .event a { color: unset; }
.calendar .event.free { border-color: $secondary; color: $secondary; }
.calendar .event.full { border-top-color: $primary; background-color: $primary; color: #fff; }
.calendar .event.past { border-top-color: $inactive; background-color: #fff; color: $inactive; }
.calendar .event.missingreport { border-top-color: $danger; background-color: $danger; color: #fff; }
.calendar .event.past.enrolled { border-top-color: $success; background-color: #fff; color: $success; }
.calendar .event.past.free { border-color: $inactive; }
.calendar .event.canceled  { border-color: $danger !important; color: $danger !important; background-color: $danger; background-image: repeating-linear-gradient(135deg, transparent, transparent 3px, white 3px, white 6px); }
.calendar .event.focus:not(.enrolled)  { border-width: 3px; border-color: $warning !important; }

.calendar .event.over { z-index: 2; }

.calendar .event .event-progress { margin: 0 5px 3px 5px; display: inline-block; vertical-align: middle; }
.calendar .event .event-progress .progress { background-color: #fff; }
.calendar .event .event-progress .progress.fill-text .progress-text { padding-top: .5rem; }

.calendar .event.enrolled .event-progress .progress-bar { background-color: $success; }
.calendar .event.full .event-progress .progress-bar { background-color: $primary; }
.calendar .event.missingreport .event-progress .progress-bar { background-color: $danger; }
.calendar .event.free .event-progress .progress-bar { background-color: $secondary; }
.calendar .event.past .event-progress .progress-bar { background-color: #dee2e6; }
.calendar .event.past.enrolled .event-progress .progress-bar { background-color: $success; }

.calendar .event.enrolled .event-progress .progress-text { color: $success; }
.calendar .event.full .event-progress .progress-text { color: $primary; }
.calendar .event.missingreport .event-progress .progress-text { color: $danger; }
.calendar .event.free .event-progress .progress-text { color: $secondary; }
.calendar .event.past .event-progress .progress-text { color: #dee2e6; }
.calendar .event.past.enrolled .event-progress .progress-text { color: $success; }

.calendar .event .event-title { display: grid; grid-template-columns: auto max-content max-content; }
.calendar .event .event-title.first-line-in-time { grid-template-columns: max-content max-content auto; }
.calendar .event .event-icons { margin-right: 2px; }
.calendar .event span.event-line-icons { margin-left: 2px; display: inline-block; width: 15px; }
.calendar .event-icons i { margin: 0 2px; min-width: .75em; text-align: center; font-size: 1.1em; }
.calendar .event-icons i.multiple-icons { margin: 0; min-width: .25em; }

.calendar.planning { display: grid; grid-template-columns: 2.5em auto; }
.calendar.planning .day { border-right: 1px solid $border; border-bottom: 1px solid $border; }
.calendar.planning .day span.number { font-weight: bold; font-size: 1.3em; color: $primary; }
.calendar.planning .day span.today { color: #fff; background-color: $primary; border-radius: 50%; }
.calendar.planning .shifts { border-bottom: 1px solid $border; }
.calendar.planning .event { margin: 5px 0; display: grid; grid-template-columns: max-content max-content auto; padding: 0 3px; }
.calendar.planning .event-icons { margin-right: 5px; }
.calendar.planning .event-icons i { margin-right: 5px; margin-top: 3px; font-size: 1.1em; }
.calendar.planning .event-icons i.multiple-icons { margin-right: 0px; }
.calendar.planning .event .event-time { margin-right: 5px; }
.calendar.planning .event .progress { margin-top: 1px; }
.calendar.planning .event .event-progress { margin: 0 2px 3px; }

.calendar.planning:not(.shifts) .day, .calendar.planning:not(.shifts) .shifts { cursor: pointer; }

.calendar-list-toggle-hidden { display: none; }

.calendar.planning .planning-month { text-transform: uppercase; color: $primary; font-size: 0.7rem; }
.planning-scroll { grid-column: 1/3; }
.planning-scroll i { font-size: 2em; }

.calendar-card .help .card-body { white-space: initial; }
.calendar-card .help .map { width: 100%; }
.calendar-card .help table { width: 100%; }
.calendar-card .help td { padding: 0 3px; }
.calendar-card .help td.absent { padding-left: 15px; }

.calendar.week .calendar-no-shifts { border-top: 1px solid $border; grid-column: 1/8; }
.calendar.planning .calendar-no-shifts { grid-column: 1/3; }